'use strict';

angular.module('cpformplastApp.inventory')
    .controller('gridController', function($scope, $state, appConfig, DataManager, Notification, Modal, $modal, Print, socket, $rootScope) {

    $scope.zones = [];
    $scope.zone = '';

    $scope.docks = [];
    $scope.dock = '';

    $scope.locations = [];
    $scope.filteredLocations = [];
    $scope.grid = {};

    $scope.searchString = '';
    $scope.showEmpty = true;
    $scope.showRawMaterial = true;
    $scope.showFinishedProduct = true;

    $scope.init = function() {
      $scope.zones = appConfig.zones;

      $scope.fetchInventory();

      socket.syncUpdates('raw-material', function(){
        $scope.fetchInventory();
      });
      socket.syncUpdates('finished-product', function(){
        $scope.fetchInventory();
      });

      $scope.setUpStickyHeaders();

      if($state.params.search) {
        $scope.searchString = $state.params.search;
      }
      if($state.params.filter == 'raw-material'){
        $scope.showEmpty = false;
        $scope.showFinishedProduct = false;
      }
      if($state.params.filter == 'finished-product'){
        $scope.showEmpty = false;
        $scope.showRawMaterial = false;
      }

      $(window).resize(function(){
        $scope.resizeGrid();
      });
      $(window).scroll(function(){
        $scope.resizeGrid();
      });
    };

    $scope.fetchInventory = function() {
      DataManager.fetchLocationsWithContent().then((data) => {
        $scope.locations = data.locations
        $scope.docks = data.docks
        $scope.buildInventory();
      })
      .catch(err => {
        console.log(err);
        Notification.error("Un problème est survenu lors du chargement de l'inventaire");
      });
    };

    $scope.buildInventory = function() {
      for (var i=0 ; i<$scope.locations.length ; i++) {
        $scope.locations[i].name = $scope.locations[i].zone + $scope.locations[i].number + '-' + $scope.locations[i].level
      }
      $scope.filterLocations();
      $scope.resizeGrid();
    };

    $scope.toggleFilter = function(filter) {
      if(filter === 'empty') {
        $scope.showEmpty = !$scope.showEmpty ;
      } else if (filter === 'rawMaterial') {
        $scope.showRawMaterial = !$scope.showRawMaterial ;
      } else if (filter === 'finishedProduct') {
        $scope.showFinishedProduct = !$scope.showFinishedProduct ;
      }
      $scope.filterLocations();
    };

    $scope.filterLocations = function(){
      const searchString = this.searchString.replace(/\s/g, '').toLowerCase();

      const matchesSearchString = (string) => string.replace(/\s/g, '').toLowerCase().indexOf(searchString) > -1

      const inventoryElementMatchesSearch = ({ job, category }) => {
        let filteringStrings = [];
        
        let hasDirectCategory = false;

        if (category) {
          const { dimension, name } = category;
          filteringStrings.push(dimension + ' ' + name)

          hasDirectCategory = true;
        }

        if (job) {
          const { number, po, item } = job;
          filteringStrings.push(number, po);

          if (item) {
            const { code, mold, name, client, category: itemCategory } = item;  
            filteringStrings.push(code, mold, name);

            if (client) filteringStrings.push(client.name);

            if (itemCategory && !hasDirectCategory) {
              filteringStrings.push(itemCategory.dimension + ' ' + itemCategory.name)
            }
          }
        }

        return filteringStrings.some(matchesSearchString);
      };

      const locationMatchesSearch = function({ name, rawMaterials, finishedProducts }) {
        if (matchesSearchString(name)) return true;
        if (rawMaterials.some(inventoryElementMatchesSearch)) return true;
        if (finishedProducts.some(inventoryElementMatchesSearch)) return true;

        return false;
      };

      const locationMatchesCriterias = function({ rawMaterials, finishedProducts }) {
        if($scope.showEmpty && rawMaterials.length===0 && finishedProducts.length===0) {
          return true;
        }
        if ($scope.showRawMaterial && rawMaterials.length>0) {
          return true;
        }
        if ($scope.showFinishedProduct && finishedProducts.length>0) {
          return true;
        }
        return false;
      };

      $scope.filteredLocations = $scope.locations.filter(location => locationMatchesSearch(location) && locationMatchesCriterias(location));

      for (let i=0 ; i<$scope.docks.length ; i++) {
        const { rawMaterials, finishedProducts } = $scope.docks[i];
        $scope.docks[i].filteredRawMaterials = rawMaterials.filter(inventoryElementMatchesSearch);
        $scope.docks[i].filteredFinishedProducts = finishedProducts.filter(inventoryElementMatchesSearch);
      }

      $scope.organiseGrid();
    };

    /****************************
    ***         Modal         ***
    ****************************/

    $scope.selectLocation = function(location) {
      $scope.fetchInventory(); //TODO: call asynchrone qui peut demander un ajustement
      $scope.openLocationAddManagementModel(location);
    };

    $scope.openLocationAddManagementModel = function (location) {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: '../../components/modal/grid/location-add-modal.html',
        controller: 'LocationAddModalController',
        resolve:{
          location: function () {
            return location;
          }
        }
      });
      modalInstance.result.then(null, function() {
        $scope.fetchInventory();
      });
    };

    $scope.openDockManagementModel = function(dock) {
      var modalInstance = $modal.open({
        animation:true,
        templateUrl:'../../components/modal/grid/dock-modal.html',
        controller: 'DockModalController',
        resolve: {
          dock: function () {
            return dock;
          }
        }
      });
      modalInstance.result.then(null, function() {
        $scope.fetchInventory();
      });
    };

    $scope.organiseGrid = function() {
      var i, j ;

      for (i=0 ; i<$scope.zones.length ; i++) {
        $scope.grid[$scope.zones[i]] = [];
      }
      for (i=0 ; i<$scope.docks.length ; i++) {
        $scope.grid[$scope.docks[i]] = [];
      }

      var rows = [];
      for (i=0 ; i<$scope.filteredLocations.length ; i++) {
        var rowIndex = -1;
        for (j=0 ; j<rows.length ; j++) {
          if(rows[j].zone === $scope.filteredLocations[i].zone && rows[j].number === $scope.filteredLocations[i].number) {
            rowIndex = j;
          }
        }
        if (rowIndex === -1) {
          rows.push({
            'zone': $scope.filteredLocations[i].zone,
            'number': $scope.filteredLocations[i].number,
            'locations': [$scope.filteredLocations[i]]
          });
        } else {
          rows[rowIndex].locations.push($scope.filteredLocations[i]);
        }
      }

      // Sort rows by name
      rows.sort(function(a,b){
        return Number.parseInt(a.number, 10) < Number.parseInt(b.number, 10) ? -1 : 1;
      });

      // Sort locations by level in rows and add the rows to their zone
      for (i=0 ; i<rows.length ; i++) {
        rows[i].locations.sort(function(a,b){
          return a.level < b.level ? -1 : 1;
        });
        $scope.grid[rows[i].zone].push(rows[i]);
      }

    };

    $scope.resizeGrid = function() {
      $('.grid-sticky-header').width($('.grid-panel').width()-30);
      $('.dock-sticky-header').width($('.grid-panel').width());
    };

    $scope.setUpStickyHeaders = function() {

      $(window).scroll(function(){
        var topnavHeight = $('#topnav').height();
        if($('.grid-panel').offset()) {
          var containerOffset = $('.grid-panel').offset().top;
        }
        var scrollPos = $(window).scrollTop() + topnavHeight;
        var stickyMarginTop = 0;
        var zoneMenuMarginTop = 0;
        var dock = '';
        var zone = '';

        if (scrollPos < containerOffset) {
          zoneMenuMarginTop = topnavHeight - scrollPos;
        } else {
          zoneMenuMarginTop = topnavHeight - containerOffset;
        }

        $('.grid-header').each(function(){
          var zoneHeaderHeight = 62;
          var zoneHeight = $(this).parent('.grid-container').height();
          var zoneOffset = $(this).offset().top;

          if (scrollPos >= zoneOffset - zoneHeaderHeight && scrollPos < zoneOffset) {
            // Entergin zone
            stickyMarginTop = zoneOffset - scrollPos - zoneHeaderHeight;
          }
          if (scrollPos >= zoneOffset && scrollPos < zoneOffset + zoneHeight) {
            // Inside zone
            zone = $(this).attr('zone');
            dock = $(this).attr('dock');
          }
        });

        $('.grid-menu').css('margin-top', zoneMenuMarginTop+'px');
        $('.grid-sticky-header').css('margin-top', stickyMarginTop + 'px');
        $scope.$apply(function(){
          $scope.zone = zone;
          $scope.dock = dock;
        });
      });

    };

    $scope.selectZone = function(zone) {
      $('html, body').animate({
        scrollTop: $('#grid-container-' + zone).position().top - $('#topnav').height() + 1
      }, 700);
    };

    $scope.init();
});
